global.notify_warning = function(text) {
  new Noty({
    text: text,
    type: 'warning'
  }).show();
}

global.notify_error = function(text) {
  new Noty({
    text: text,
    type: 'error'
  }).show();
}

global.notify_danger = function(text) {
  global.notify_error(text);
}

global.notify_info = function(text) {
  new Noty({
    text: text,
    type: 'info'
  }).show();
}

global.notify_success = function(text) {
  new Noty({
    text: text,
    type: 'success'
  }).show();
}


function openNavigation() {
  document.getElementById("mySidebar").style.width = "250px";
  document.getElementById("main").style.marginLeft = "250px";
}

function closeNavigation() {
  document.getElementById("mySidebar").style.width = "0";
  document.getElementById("main").style.marginLeft= "0";
}

function close_fullscreen_layout(){
  $('.main-sidebar').addClass('hide');
  $('.navbar').addClass('hide');
  $('.main-footer').addClass('hide');
  $('.content-wrapper').addClass('full');
  $(this).addClass('hide');
  $('#show_fullscreen_layout').removeClass('hide');
  $(window).trigger('resize');
}

function show_fullscreen_layout(){
  $('.main-sidebar').removeClass('hide');
  $('.navbar').removeClass('hide');
  $('.main-footer').removeClass('hide');
  $('.content-wrapper').removeClass('full');
  $(this).addClass('hide');
  $('#close_fullscreen_layout').removeClass('hide');
  $(window).trigger('resize');
}


$(document).on('click', '#close_fullscreen_layout', close_fullscreen_layout);

$(document).on('click', '#show_fullscreen_layout', show_fullscreen_layout);