/*
 * Image preview script 
 * powered by jQuery (http://www.jquery.com)
 * 
 * written by Alen Grakalic (http://cssglobe.com)
 * 
 * for more info visit http://cssglobe.com/post/1695/easiest-tooltip-and-image-preview-using-jquery
 *
 */
 
function imagePreview(){	
	/* CONFIG */
		
		xOffset = 10;
		yOffset = 30;
		
		// these 2 variable determine popup's distance from the cursor
		// you might want to adjust to get the right result
		
	/* END CONFIG */
		$('body').on('mouseenter', '.image_preview', function(e){
			this.t = this.title;
			this.title = "";	
			var c = (this.t != "") ? "<br/>" + this.t : "";
			$("body").append("<p id='image_preview'><img src='"+ this.src +"' alt='Image preview' />"+ c +"</p>");								 
			$("#image_preview")
				.css("top",(e.pageY - xOffset) + "px")
				.css("left",(e.pageX + yOffset) + "px")
				.css("z-index", 100000)
				.fadeIn("fast");	
		});

		$('body').on('mouseleave', '.image_preview', function(e){
				this.title = this.t;	
				$("#image_preview").remove();
		});
		
		$(document).on('mousemove', '.image_preview', function(e){
			$("#image_preview")
			.css("top",(e.pageY - xOffset) + "px")
			.css("left",(e.pageX + yOffset) + "px");
		});	
};


// starting the script on page load
$(document).ready(function(){
	imagePreview();
});